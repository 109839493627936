<template>
	<!-- 个人中心 分销模块 组件 -->
	<div>
		<a-card :bordered="false" class="header-solid h-full mb-24" style="overflow: hidden" :bodyStyle="{ padding: 0 }">
			<template #title>
				<a-row type="flex" align="middle">
					<a-col :span="24" :md="12">
						<h5 class="font-semibold m-0">我的收益</h5>
					</a-col>
				</a-row>
			</template>
			<a-table :columns="earningsTableColumns" :data-source="earningsTableData" :pagination="false" :loading="earningsTableLoading">
				<template slot="dengji" slot-scope="dengji">
					<a-tag class="tag-status" :class="dengji.level > 0 ? 'ant-tag-primary' : 'ant-tag-muted'">
						{{ dengji.text }}
					</a-tag>
				</template>
				<template slot="editBtn" slot-scope="row">
					<a-button type="link" :data-id="row.key">提现</a-button>
				</template>
			</a-table>
		</a-card>

		<a-card :bordered="false" class="header-solid h-full" style="overflow: hidden" :bodyStyle="{ padding: 0 }">
			<template #title>
				<a-row type="flex" align="middle">
					<a-col :span="24" :md="24">
						<a-radio-group v-model="tabsId" size="small">
							<a-radio-button :value="item.id" v-for="(item, index) in tabsList" :key="'table_tabs_' + index">{{ item.title }}</a-radio-button>
						</a-radio-group>
					</a-col>
				</a-row>
			</template>
			<div v-if="tabsId === 1">
				<a-table :columns="inviteTableColumns" :data-source="inviteTableData" :pagination="false" :loading="inviteTableLoading">
					<template slot="avatar" slot-scope="avatar">
						<a-avatar shape="square" :src="avatar" />
					</template>
				</a-table>
			</div>
			<div v-if="tabsId === 2">
				<a-table :columns="brokerageTableColumns" :data-source="brokerageTableData" :pagination="false" :loading="brokerageTableLoading"></a-table>
			</div>
		</a-card>
	</div>
</template>

<script>
	export default {
		props: {
			data: {
				type: Array,
				default: () => []
			},
			columns: {
				type: Array,
				default: () => []
			}
		},
		data() {
			return {
				// 个人收益
				earningsTableColumns: [
					{
						title: "可提现金额",
						dataIndex: "ketixian",
						class: "text-muted"
					},
					{
						title: "今日收益",
						dataIndex: "jinri",
						class: "text-muted"
					},
					{
						title: "累计收益",
						dataIndex: "leiji",
						class: "text-muted"
					},
					{
						title: "当前等级",
						dataIndex: "dengji",
						scopedSlots: { customRender: "dengji" },
						class: "text-muted"
					},
					{
						title: "",
						scopedSlots: { customRender: "editBtn" },
						width: 50
					}
				],
				earningsTableData: [],
				// 邀请列表
				inviteTableColumns: [
					{
						title: "用户ID",
						dataIndex: "id",
						class: "text-muted"
					},
					{
						title: "用户头像",
						dataIndex: "avatar",
						scopedSlots: { customRender: "avatar" }
					},
					{
						title: "用户昵称",
						dataIndex: "nickname",
						class: "text-muted"
					},
					{
						title: "关系",
						dataIndex: "relation",
						class: "text-muted"
					},
					// {
					// 	title: "累计消费",
					// 	dataIndex: "expense",
					// 	class: "text-muted"
					// },
					{
						title: "邀请时间",
						dataIndex: "time",
						class: "text-muted"
					}
				],
				inviteTableData: [],
				// 分佣列表
				brokerageTableColumns: [
					{
						title: "用户ID",
						dataIndex: "id",
						class: "text-muted"
					},
					{
						title: "用户昵称",
						dataIndex: "nickname",
						class: "text-muted"
					},
					{
						title: "关系",
						dataIndex: "relation",
						class: "text-muted"
					},
					{
						title: "消费类型",
						dataIndex: "type",
						class: "text-muted"
					},
					{
						title: "充值金额",
						dataIndex: "money",
						class: "text-muted"
					},
					{
						title: "分佣",
						dataIndex: "brokerage",
						class: "text-muted"
					},
					{
						title: "充值时间",
						dataIndex: "time",
						class: "text-muted"
					}
				],
				brokerageTableData: [],
				tabsId: 1,
				tabsList: [
					{ id: 1, title: "邀请列表" },
					{ id: 2, title: "佣金明细" }
				],
				earningsTableLoading: true,
				inviteTableLoading: true,
				brokerageTableLoading: true
			}
		},
		watch: {
			tabsId(newValue, oldValue) {
				console.log(newValue, "切换分类")
			}
		},
		created() {
			this.getInviteData()
			this.getBrokerageData()
			this.getResellerData()
		},
		methods: {
			// 获取个人分销商数据
			getResellerData() {
				this.$http("place.data").then(res => {
					if (res.code === 1) {
						this.earningsTableData = [
							{
								key: 1,
								ketixian: res.data.money,
								jinri: res.data.today_reseller_money,
								leiji: res.data.reseller_money,
								dengji: {
									text: res.data.reseller ? res.data.reseller.reseller_json.name : "无",
									level: res.data.reseller ? res.data.reseller.reseller_json.level : 0
								}
							}
						]
					}
					this.earningsTableLoading = false
				})
			},
			// 获取佣金数据
			getBrokerageData() {
				this.$http("place.brokerage").then(res => {
					if (res.code === 1) {
						if (res.data.list && res.data.list.length) {
							const arr = []
							res.data.list.map(item => {
								arr.push({
									key: item.id,
									id: item.id,
									nickname: item.nickname,
									relation: item.type_text,
									type: item.order_type_text,
									money: item.pay_money,
									brokerage: item.money,
									time: item.createtime
								})
							})
							this.brokerageTableData = this.brokerageTableData.concat(arr)
						}
					}
					this.brokerageTableLoading = false
				})
			},
			// 获取邀请数据
			getInviteData() {
				this.$http("place.team").then(res => {
					if (res.code === 1) {
						if (res.data.reseller_user && res.data.reseller_user.length) {
							const arr = []
							res.data.reseller_user.map(item => {
								arr.push({
									key: item.id,
									id: item.id,
									avatar: item.avatar,
									nickname: item.nickname,
									relation: item.type == 1 ? "直推" : "间推",
									time: item.createtime
								})
							})
							this.inviteTableData = this.inviteTableData.concat(arr)
						}
					}
					this.inviteTableLoading = false
				})
			}
		}
	}
</script>
